<div class="table-container" [ngClass]="isDesktop?'desktop-content':''">
    <ng-container *ngIf="activePrizes || prizeList">
        <div class="category-title-h1" i18n>Free Spins:</div>
        <!-- <table mat-table *ngIf="activePrizes?.length > 0 else nothing" [dataSource]="activePrizes" class="mat-elevation-z8">

            <ng-container matColumnDef="exp-date">
                <th mat-header-cell *matHeaderCellDef i18n>Exp. date</th>
                <td mat-cell *matCellDef="let element"> {{element.end_date | date: 'shortDate'}} </td>
            </ng-container>

            <ng-container matColumnDef="freespins">
                <th mat-header-cell *matHeaderCellDef i18n>Num.</th>
                <td mat-cell *matCellDef="let element"> {{element.freespins}} </td>
            </ng-container>

            <ng-container matColumnDef="games">
                <th mat-header-cell *matHeaderCellDef i18n>Games</th>
                <td mat-cell *matCellDef="let element">
                    {{element.games}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsFreeSpinsActive"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsFreeSpinsActive;"></tr>
        </table> -->

        <div class="grid-wrapper header active-prizes">
            <div class="header" i18n>Exp. date</div>
            <div class="header" i18n>Count</div>
            <div class="header last" i18n *ngIf="isDesktop">Games</div>
        </div>
        <ng-container *ngIf="activePrizes?.length > 0 else nothing">
            <div class="grid-wrapper prize active-prizes" *ngFor="let element of activePrizes;let index = index;">
                <div class="prize-row1">{{element.end_date | date: 'shortDate'}}</div>
                <div class="prize-row1">{{element.freespins}}</div>
                <ng-container *ngIf="!isDesktop else desktopactive">
                    <div class="m-game-cell">
                        <div class="prize-row2" *ngFor="let game of element.games">
                            <img (click)="openGame(index ,game.id)" loading=lazy alt="{{game.name}}" [src]="getThumbnailUrl(game.id)" />
                            <div (click)="openGame(index, game.id)" class="game-name">{{game.name}}</div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #desktopactive>
                    <div class="prize-row1 d-game-cell">
                        <div class="dim" *ngFor="let game of element.games">
                            <img (click)="openGame(index ,game.id)" loading=lazy alt="{{game.name}}" [src]="getThumbnailUrl(game.id)" />
                            <a class="game-name" (click)="openGame(index ,game.id)">{{game.name}}</a>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-template #noprizes>
    <div class="no-prizes" i18n>There are no active prizes at the moment.</div>
</ng-template>
<ng-template #nothing>
    <div class="no-prizes" i18n>Your list is empty.</div>
</ng-template>