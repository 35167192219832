import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FREE_SPINS_LIST, FREE_SPINS_QUEUE } from '../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { BaseWalletService } from '../http/base-wallet.service';
import { HOST_NAME } from '../../global.tokens';
import { ActivePrizes } from './wallet.models';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FreeSpinListService extends BaseWalletService {


  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public requestFreeSpinsActive(): Observable<ActivePrizes[]> {
    return this.get<any>(FREE_SPINS_QUEUE).pipe(
      map(resp => {
        return resp.results as ActivePrizes[];
      })
    )
  }

  public getFreeSpinsList(): Observable<ActivePrizes[]> {
    return this.get<any>(FREE_SPINS_LIST).pipe(
      map(resp => {
        return resp.results as ActivePrizes[];
      })
    )
  }
}
